const hoverCircle = function () {
  let isSPorTab = false;
  const ua = navigator.userAgent.toLowerCase();
  if (
    /android|ipod|ipad|iphone|macintosh/.test(ua) &&
    `ontouchend` in document
  ) {
    isSPorTab = true;
  }

  let pxToRemDivision = 10;
  const targetPos = (
    targetRect: ClientRect,
    mouseClientX: number,
    mouseClientY: number,
  ) => ({
    mouseX: (mouseClientX - targetRect.left) / pxToRemDivision,
    mouseY: (mouseClientY - targetRect.top) / pxToRemDivision,
  });

  const hoverArea: NodeListOf<HTMLElement> =
    document.querySelectorAll<HTMLElement>(`.js-hover-circle`);
  hoverArea!.forEach((hoverAreaElement) => {
    let appendTargetElement: HTMLElement = hoverAreaElement;
    let isPC: boolean = window.innerWidth > 980 && !isSPorTab;
    let reverseType = false;
    let stickTarget = false;
    let bgWhite = false;
    let reverseButton = false;
    const circleElement: HTMLElement = document.createElement(`span`);
    circleElement.classList.add(`js-hover-circle-inner`);

    if (
      appendTargetElement.querySelectorAll<HTMLElement>(
        `.js-hover-circle-inner`,
      ).length > 0
    ) {
      return;
    }

    const { circleType } = hoverAreaElement.dataset;
    switch (circleType) {
      case `stick`:
        circleElement.classList.add(`--stick`);
        break;
      case `stick-target`:
        reverseType = true;
        stickTarget = true;
        appendTargetElement =
          hoverAreaElement.querySelector<HTMLElement>(`.js-stick-target`);
        hoverAreaElement.classList.add(`--reverse`);
        hoverAreaElement.classList.add(`--stick-target`);
        circleElement.classList.add(`--stick`);
        break;
      case `stick-target-noReverse`:
        stickTarget = true;
        appendTargetElement =
          hoverAreaElement.querySelector<HTMLElement>(`.js-stick-target`);
        circleElement.classList.add(`--stick`);
        break;
      case `big`:
        circleElement.classList.add(`--big`);
        break;
      case `reverse`:
        reverseType = true;
        hoverAreaElement.classList.add(`--reverse`);
        break;
      case `reverse-word`:
        bgWhite = true;
        reverseType = true;
        hoverAreaElement.classList.add(`--reverseWord`);
        break;
      case `reverse-button`:
        reverseType = true;
        reverseButton = true;
        hoverAreaElement.classList.add(`--reverse`);
        break;
      case `white`:
        circleElement.classList.add(`--white`);
        break;
      default:
        break;
    }

    const { circleInner } = hoverAreaElement.dataset;
    let circleInnerText = ``;
    let circleElementMixBlend;
    let circleElementBgWhite;
    let circleElementReverseButton;
    switch (circleInner) {
      case `arrow-right`:
        circleInnerText = `<span class="js-hover-circle-inner-img-svg --arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><path d="M32,16l-5-4.5v9Z" style="fill:#fff;"/><line y1="16" x2="29" y2="16" style="fill:#fff;stroke:#fff;stroke-miterlimit:10;"/></svg></span>`;
        break;
      case `arrow-right-orange`:
        circleInnerText = `<span class="js-hover-circle-inner-img-svg --arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><path d="M32,16l-5-4.5v9Z" style="fill:#FD6C5E;"/><line y1="16" x2="29" y2="16" style="fill:#FD6C5E;stroke:#FD6C5E;stroke-miterlimit:10;"/></svg></span>`;
        break;
      case `arrow-down-orange`:
        circleInnerText = `<span class="js-hover-circle-inner-img-svg --arrow --down"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><path d="M32,16l-5-4.5v9Z" style="fill:#FD6C5E;"/><line y1="16" x2="29" y2="16" style="fill:#FD6C5E;stroke:#FD6C5E;stroke-miterlimit:10;"/></svg></span>`;
        break;
      case `arrow-upperRight`:
        circleInnerText = `<span class="js-hover-circle-inner-img-svg --arrow --upperRight"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><path d="M32,16l-5-4.5v9Z" style="fill:#fff;"/><line y1="16" x2="29" y2="16" style="fill:#fff;stroke:#fff;stroke-miterlimit:10;"/></svg></span>`;
        break;
      case `arrow-upperRight-orange`:
        circleInnerText = `<span class="js-hover-circle-inner-img-svg --arrow --upperRight --orange"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><path d="M32,16l-5-4.5v9Z" style="fill:#FD6C5E;"/><line y1="16" x2="29" y2="16" style="fill:#FD6C5E;stroke:#FD6C5E;stroke-miterlimit:10;"/></svg></span>`;
        break;
      case `about`:
        circleInnerText = `About Netcom`;
        break;
      case `card`:
        circleInnerText = `<div class="js-hover-circle-inner-card"></div>`;
        break;
      default:
        break;
    }

    circleElement.innerHTML = circleInnerText;
    appendTargetElement.appendChild(circleElement);
    if (reverseType) {
      circleElementMixBlend = <HTMLElement>circleElement.cloneNode(true);
      circleElementMixBlend.classList.add(`js-hover-circle-inner-mix-blend`);
      appendTargetElement.appendChild(circleElementMixBlend);
    }
    if (bgWhite) {
      circleElementBgWhite = <HTMLElement>circleElement.cloneNode(true);
      circleElementBgWhite.classList.add(`js-hover-circle-inner-bg-white`);
      appendTargetElement.appendChild(circleElementBgWhite);
    }
    if (reverseButton) {
      circleElementReverseButton = document.createElement(`div`);
      circleElementReverseButton.classList.add(
        `js-button-02__mix-blend-lighten`,
      );
      appendTargetElement.appendChild(circleElementReverseButton);
    }

    const circleStick = circleType?.includes(`stick`);
    let stickCount = 0;
    const stickPosDiffX = -7;
    let stickPosDiffY = 1.8;
    const stickPos = {
      x: 10,
      y: 1,
    };
    const circlePos = {
      x: 0,
      y: 0,
    };
    const mouseClient = {
      x: 0,
      y: 0,
    };
    let isHover = false;
    const delay = 10;

    const stickPosFunc = (targetRect: ClientRect) => {
      if (stickTarget) {
        stickPos.x =
          (appendTargetElement.offsetLeft + targetRect.width + stickPosDiffX) /
          pxToRemDivision;
        stickPos.y =
          (appendTargetElement.offsetTop +
            targetRect.height / 2 +
            stickPosDiffY) /
          pxToRemDivision;
      } else {
        stickPos.x = (targetRect.width + stickPosDiffX) / pxToRemDivision;
        stickPos.y = (targetRect.height / 2 + stickPosDiffY) / pxToRemDivision;
      }
      circleElement.style.left = `${stickPos.x}rem`;
      circleElement.style.top = `${stickPos.y}rem`;
      if (reverseType) {
        circleElementMixBlend!.style.left = `${stickPos.x}rem`;
        circleElementMixBlend!.style.top = `${stickPos.y}rem`;
      }
      if (bgWhite) {
        circleElementBgWhite!.style.left = `${circlePos.x}rem`;
        circleElementBgWhite!.style.top = `${circlePos.y}rem`;
      }
    };

    const circleMoveFunc = () => {
      const moveCircle = () => {
        const targetRect: ClientRect = hoverAreaElement.getBoundingClientRect();
        const { mouseX, mouseY } = targetPos(
          targetRect,
          mouseClient.x,
          mouseClient.y,
        );
        circlePos.x += (mouseX - circlePos.x) / delay;
        circlePos.y += (mouseY - circlePos.y) / delay;
        circleElement.style.left = `${circlePos.x}rem`;
        circleElement.style.top = `${circlePos.y}rem`;
        if (reverseType) {
          circleElementMixBlend!.style.left = `${circlePos.x}rem`;
          circleElementMixBlend!.style.top = `${circlePos.y}rem`;
        }
        if (bgWhite) {
          circleElementBgWhite!.style.left = `${circlePos.x}rem`;
          circleElementBgWhite!.style.top = `${circlePos.y}rem`;
        }
        if (!isHover || !isPC) return;
        requestAnimationFrame(moveCircle);
      };

      if (circleStick) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            stickPosFunc(appendTargetElement.getBoundingClientRect());
          }
        });
        setTimeout(() => {
          stickPosFunc(appendTargetElement.getBoundingClientRect());
          resizeObserver.observe(hoverAreaElement);
        }, 100);
        setTimeout(() => {
          resizeObserver.unobserve(hoverAreaElement);
        }, 1000);
      }

      setTimeout(() => {
        circleElement.style.opacity = `1`;
        if (bgWhite) circleElementBgWhite!.style.opacity = `1`;
        if (reverseType) circleElementMixBlend!.style.opacity = `1`;
      }, 500);

      const stickCircle = () => {
        circlePos.x += (stickPos.x - circlePos.x) / delay;
        circlePos.y += (stickPos.y - circlePos.y) / delay;
        circleElement.style.left = `${circlePos.x}rem`;
        circleElement.style.top = `${circlePos.y}rem`;
        if (reverseType) {
          circleElementMixBlend!.style.left = `${circlePos.x}rem`;
          circleElementMixBlend!.style.top = `${circlePos.y}rem`;
        }
        if (bgWhite) {
          circleElementBgWhite!.style.left = `${circlePos.x}rem`;
          circleElementBgWhite!.style.top = `${circlePos.y}rem`;
        }
        stickCount++;
        if (stickCount > 70 || isHover) return;
        requestAnimationFrame(stickCircle);
      };

      hoverAreaElement.addEventListener(`mousemove`, (e) => {
        mouseClient.x = e.clientX;
        mouseClient.y = e.clientY;
      });
      hoverAreaElement.addEventListener(`mouseenter`, (e) => {
        stickCount = 0;
        mouseClient.x = e.clientX;
        mouseClient.y = e.clientY;
        const targetRect: ClientRect = hoverAreaElement.getBoundingClientRect();
        const { mouseX, mouseY } = targetPos(
          targetRect,
          mouseClient.x,
          mouseClient.y,
        );
        circlePos.x = mouseX;
        circlePos.y = mouseY;
        if (isPC) {
          circleElement.classList.add(`--active`);
          if (bgWhite) circleElementBgWhite!.classList.add(`--active`);
          if (reverseType) circleElementMixBlend!.classList.add(`--active`);
          isHover = true;
          moveCircle();
        }
      });
      hoverAreaElement.addEventListener(`mouseleave`, () => {
        circleElement.classList.remove(`--active`);
        if (bgWhite) circleElementBgWhite!.classList.remove(`--active`);
        if (reverseType) circleElementMixBlend!.classList.remove(`--active`);
        isHover = false;
        if (circleStick && isPC) stickCircle();
      });
    };

    const onWindowResize = () => {
      if (window.innerWidth > 1700) {
        stickPosDiffY = 1.3;
        pxToRemDivision = 11;
      }

      if (stickTarget)
        stickPosFunc(appendTargetElement.getBoundingClientRect());
      if (window.innerWidth > 980 && !isSPorTab) {
        isPC = true;
        return;
      }
      isPC = false;
      circleElement.classList.remove(`--active`);
      if (bgWhite) circleElementBgWhite!.classList.remove(`--active`);
      if (reverseType) circleElementMixBlend!.classList.remove(`--active`);
    };
    window.addEventListener(`resize`, onWindowResize);
    onWindowResize();
    circleMoveFunc();
  });
};

export default hoverCircle;
