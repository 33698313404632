// ■コンポーネント説明
// ユーティリティ

// 年月日の区切り文字をドットに変換
function formatDate(dt: Date): string {
  const y = dt.getFullYear();
  const m = `00${dt.getMonth() + 1}`.slice(-2);
  const d = `00${dt.getDate()}`.slice(-2);

  return `${y}.${m}.${d}`;
}

// カテゴリ名の整形
function formatCategoryName(category: string): string {
  let formatName = ``;
  switch (category) {
    case `web-solution`:
      formatName = `Web Solution`;
      break;
    case `cloud-solution`:
      formatName = `Cloud Solution`;
      break;
    case `web-creative`:
      formatName = `Web Creative`;
      break;
    case `digital-marketing`:
      formatName = `Digital Marketing`;
      break;
    default:
      break;
  }
  return formatName;
}

// 任意の文字数にカット
function concatTxt(txt: string, limit: number): string {
  let formatTxt = txt;
  if (txt.length > limit) {
    formatTxt = `${txt.substr(0, limit)}…`;
  }
  return formatTxt;
}

// HTMLタグを削除する
function deleteTag(str: string): string {
  let formatTxt = str;
  if (str == null) {
    formatTxt = ``;
  }
  return formatTxt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, ``);
}

// カンマ区切りの文字列配列を空白区切りの単一文字列にする
function commaShapeBlank(txtArray: string[]): string {
  let returnTxt = ``;
  if (txtArray === null || txtArray === undefined) {
    return returnTxt;
  }
  txtArray.forEach((txtArrayItem) => {
    if (returnTxt === ``) {
      returnTxt = `${txtArrayItem}`;
    } else {
      returnTxt = `${returnTxt} ${txtArrayItem}`;
    }
  });
  return returnTxt;
}

// NEWSカテゴリ名の整形
function newsLabel(tagTxt: string): string {
  switch (tagTxt) {
    case `お知らせ`:
      return `INFO`;
    case `イベント`:
      return `EVENT`;
    default:
      return `NEWS`;
  }
}

export {
  formatDate,
  formatCategoryName,
  concatTxt,
  deleteTag,
  commaShapeBlank,
  newsLabel,
};
