// ■コンポーネント説明
// metaタグ等設定用コンポーネント

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import CDNReplace from '@/components/CDNReplace';

export interface SEOProps {
  description?: string;
  keyword?: string;
  lang?: string;
  meta?: any[];
  title: string;
  ogpImage?: string;
}

const SEO: React.FunctionComponent<SEOProps> = (props) => {
  const { description, keyword, lang, meta, title, ogpImage } = props;
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            author
            siteUrl
          }
        }
        microcmsEnv {
          commonDescription
          commonKeywords
          commonThumbnail {
            url
          }
        }
      }
    `,
  );

  return (
    <Helmet
      titleTemplate={title !== `` ? data.site.siteMetadata.titleTemplate : ``}
    >
      <html lang={lang} />
      <title>{title || data.site.siteMetadata.title}</title>
      <meta
        name="description"
        content={description || data.microcmsEnv.commonDescription}
      />
      <meta
        name="keyword"
        content={keyword || data.microcmsEnv.commonKeywords}
      />
      <meta
        property="og:title"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        property="og:description"
        content={description || data.microcmsEnv.commonDescription}
      />
      <meta
        property="og:image"
        content={CDNReplace(ogpImage || data.microcmsEnv.commonThumbnail.url)}
      />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={data.site.siteMetadata.author} />
      <meta
        name="twitter:title"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        name="twitter:description"
        content={description || data.microcmsEnv.commonDescription}
      />
      <meta
        name="twitter:text:title"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        name="twitter:text:description"
        content={description || data.microcmsEnv.commonDescription}
      />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
};

export default SEO;
