// ■コンポーネント説明
// パンくずリスト

import React from 'react';
import * as Util from '@/components/Util';
import { Link } from 'gatsby';
import * as styles from '@/styles/module/breadcrumb.module.scss';

export interface UIBreadcrumbProps {
  /**
   * 上の階層のテキスト、リンク
   */
  UpperItem?: string[][];
  /**
   * 現在地のテキスト
   */
  CurrentItem?: string;
}

const UIBreadcrumb: React.FC<UIBreadcrumbProps> = (props) => {
  const { UpperItem, CurrentItem } = props;
  const itemLength = UpperItem.length;

  return (
    <>
      <ol
        className={styles.breadcrumb}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        <li
          className={styles.breadcrumb__item}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <Link to="/" itemProp="item">
            <span itemProp="name">TOP</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        {UpperItem.map((UpperItems, index) => (
          <li
            key=""
            className={styles.breadcrumb__item}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Link key={UpperItems[1]} to={UpperItems[1]} itemProp="item">
              <span itemProp="name">{UpperItems[0]}</span>
            </Link>
            <meta itemProp="position" content={String(index + 2)} />
          </li>
        ))}
        <li
          className={`${styles.breadcrumb__item} ${styles.__current}`}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <span itemProp="name">{Util.concatTxt(CurrentItem, 20)}</span>
          <meta itemProp="position" content={String(itemLength + 2)} />
        </li>
      </ol>
    </>
  );
};
UIBreadcrumb.defaultProps = {
  UpperItem: [
    [`UpperItem1`, `/`],
    [`UpperItem2`, `/`],
    [`UpperItem3`, `/`],
  ],
  CurrentItem: `CurrentItem`,
};

export default UIBreadcrumb;
