// ■コンポーネント説明
// CDNの文字列置換用コンポーネント

import { graphql, useStaticQuery } from 'gatsby';

export default function CDNReplace(inputText: string): string {
  let returnText = ``;
  const data = useStaticQuery<GatsbyTypes.CDNReplaceQuery>(
    graphql`
      query CDNReplace {
        microcmsEnv {
          commonCDN
        }
      }
    `,
  );

  if (inputText) {
    returnText = inputText
      .replace(
        /https:\/\/files.microcms-assets.io\//g,
        data.microcmsEnv.commonCDN,
      )
      .replace(
        /https:\/\/images.microcms-assets.io\//g,
        data.microcmsEnv.commonCDN,
      );
  }

  return returnText;
}
