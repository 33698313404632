import { graphql, PageProps } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import SEO from '@/components/seo';
import hoverCircle from '@/components/Interaction/hoverCircle';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import UIBox from '@/components/UI/UIBox';
import { Context } from '@/components/Layout';

import * as textLead from '@/styles/object/text-lead.module.scss';
import * as pageHeader from '@/styles/object/page-header.module.scss';

const ServiceCategoryWebSolution: React.FC<
  PageProps<GatsbyTypes.ServiceCategoryWebSolutionQuery>
> = ({ data }) => {
  const loading = useContext(Context);

  useEffect(() => {
    hoverCircle();
  }, []);

  return (
    !loading && (
      <>
        <SEO
          title={`${data.microcmsService.title} | サービス`}
          description={data.microcmsService.description}
        />
        <div className="page-wrap --web-solution">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeader.page_header}>
            <h1 className={pageHeader.page_header__title}>
              <small className={pageHeader.page_header__head_text}>
                {data.microcmsService.title}
              </small>
              <div className={pageHeader.page_header__text}>
                {data.microcmsService.subTitle}
              </div>
            </h1>
          </header>
          <div className="page-contents-box">
            <div className="section">
              <p
                className={textLead.text_lead}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data.microcmsService.description,
                }}
              />
            </div>
            <div className="section">
              <div className="box-2rows">
                {data.allMicrocmsService.edges.map(
                  ({ node }) =>
                    node.subCategory.length === 0 && (
                      <UIBox
                        key={node.serviceId}
                        title={node.title}
                        description={node.description}
                        link={`/service/${node.category}/${node.serviceId}/`}
                        displayIndex={node.displayIndex}
                      />
                    ),
                )}
              </div>
            </div>
          </div>
        </div>
        <UIBreadcrumb
          UpperItem={[[`Service`, `/service`]]}
          CurrentItem="Web Solution"
        />
      </>
    )
  );
};

export const query = graphql`
  query ServiceCategoryWebSolution {
    allMicrocmsService(
      filter: {
        category: { in: "web-solution" }
        serviceId: { ne: "web-solution" }
      }
      sort: { fields: sort }
    ) {
      edges {
        node {
          id
          serviceId
          title
          category
          subCategory
          description
          displayIndex
        }
      }
    }
    microcmsService(serviceId: { eq: "web-solution" }) {
      id
      title
      subTitle
      description
    }
  }
`;

export default ServiceCategoryWebSolution;
