// ■コンポーネント説明
// リンク付きグレーボックス

import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import hoverCircle from '@/components/Interaction/hoverCircle';

import * as styles from '@/styles/object/box-01.module.scss';

export interface UIBoxProps {
  /**
   * タイトル
   */
  title?: string;
  /**
   * 概要
   */
  description?: string;
  /**
   * リンク
   */
  link?: string;
  /**
   * 一覧への表示のみ
   */
  displayIndex?: boolean;
}

const UIBox: React.FC<UIBoxProps> = (props) => {
  const { title, description, link, displayIndex } = props;

  useEffect(() => {
    hoverCircle();
  }, []);

  return (
    displayIndex ?
      <>
        <div
          className={`box-2rows__item ${styles.box_01}`}
        >
          <h3 className="title-lv4">{title}</h3>
          <p
            className="text-01"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </>
      :
      <>
        <Link
          to={link}
          className={`box-2rows__item ${styles.box_01} ${styles.__link} js-hover-circle`}
          data-circle-type=""
          data-circle-inner="arrow-right"
        >
          <h3 className="title-lv4">{title}</h3>
          <p
            className="text-01"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Link>
      </>
  );
};
UIBox.defaultProps = {
  title: `title`,
  description: `Sample description!!Sample description!!Sample description!!Sample description!!`,
  link: `/`,
};

export default UIBox;
